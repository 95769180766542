var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), !_vm.courseHasParticipants ? _c('v-btn', {
          attrs: {
            "color": "white",
            "disabled": _vm.isCourseDone,
            "outlined": "",
            "data-cy": "addParticipant"
          },
          on: {
            "click": _vm.navigateToParticipantList
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til deltaker ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_vm.schedule ? _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.schedule,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-row', [!_vm.courseHasParticipants ? _c('v-col', {
          staticClass: "py-0 d-flex justify-end align-center",
          attrs: {
            "cols": "5"
          }
        }, [_c('p', {
          staticClass: "mb-0 mx-2 subtitle-1"
        }, [_vm._v("Kurset har ingen deltakere")])]) : _vm._e(), _c('v-col', {
          staticClass: "py-0 d-flex align-end",
          attrs: {
            "cols": "6"
          }
        }, [_c('dl', {
          staticClass: "px-3 subtitle-1 font-weight-light justify-space-between flex-direction: row"
        }, [_c('dt', [_vm._v("Fullførte timer:")]), _c('dd', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm.courseValues && _vm.courseValues.completedScheduledHours))]), _c('dt', [_vm._v("Gjenstående timer:")]), _c('dd', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm.courseValues && _vm.courseValues.remainingScheduledHours))])])])], 1)];
      },
      proxy: true
    }, {
      key: "item.start",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.formatDateTime(value)) + " ")];
      }
    }, {
      key: "item.end",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedTime(value)) + " ")];
      }
    }, {
      key: "item.isAllAttendancesRegistered",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value && _vm.courseHasParticipants ? "Ja" : "Nei") + " ")];
      }
    }, {
      key: "item.hourAmount",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getDifferenceInHours(item)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "openCourseAttendance"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openCourseAttendanceParticipant(item.start);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Åpne dag")])])];
      }
    }], null, true)
  }) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }